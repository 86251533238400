.home {
  z-index: 1;
  padding: 1.5rem 0;
  text-align: left;

  &__container {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    row-gap: 7rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__content {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 130px repeat(1, 1fr);
    padding-top: 4rem;
    column-gap: 2rem;
    align-items: center;
  }

  &__title {
    font-size: 3.5rem;
    margin-bottom: .25rem;
  }

  &__subtitle {
    position: relative;
    font-size: 1.55rem;
    padding-left: 5.4rem;
    font-weight: 500;
    margin-bottom: 1rem;

    &:before {
      content: "";
      position: absolute;
      width: 70px;
      height: 1px;
      background-color: hsl(0, 0%, 46%);
      left: 0;
      top: 1rem;
    }
  }

  &__description {
    padding: .75rem;
    font-size: 1.1rem;
    font-weight: 300;
    max-width: 600px;

    > span {
      font-size: 1.2rem;
      border-bottom: none;
      background-image: linear-gradient(0deg,rgba(1, 151, 149, 0.513) 0,rgba(5, 143, 136, 0.4) 30%,transparent 40%,transparent);    
    }

    &-link {
      margin-top: 5rem;
    }
  }

  &__social {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 34rem;
    padding: .75rem;

    > a {
      margin-top: 3rem;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 480px){
  .home {
    text-align: center;

    &__content {
      column-gap: 0;
      padding: 1rem;
    }

    &__title {
      font-size: 2rem;
    }
  
    &__subtitle {
      position: unset;
      font-size: 1rem;
      padding: 0;

      &:before {
        display: none;
      }
    }

    &__description {

      &-link {
        margin-top: 4rem;
        margin-left: 1rem;
      }
    }

    &__social {
      height: 38rem;
    }
  }
}