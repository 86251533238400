.scroll {
  margin-left: 9.25rem;
  margin: 5rem auto;

  &__button {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  &__wheel {
    animation: scroll 2s ease infinite;
  }

  &__name {
    font-size: 1.1rem;
    font-weight: 500;
    margin-right: .25rem;
    margin-left: 2px;
  }

  &__arrow {
    font-size: 1.25rem;
  }
}

  
@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(3.75rem);
  }
}