.card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 4rem;
  border-radius: 1.25rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-top: 3rem;

  &__title {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  &__box {
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;
    text-align: left;
  }

  &__group {
    display: grid;
    align-items: flex-start;
    row-gap: 1rem;
  }

  &__data {
    display: flex;
    column-gap: 0.5rem;
  }

  &__name {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 18px;
   }

  &__level {
    font-size: .9rem;
    font-weight: 500;
    opacity: .6;
  }
}