.projects {
  padding: .5rem 0 2rem 0;
  
  &__title {
    font-size: 2.6rem;
    font-weight: 600;
  }
  
  &__subtitle {
    font-size: 1rem;
    font-weight: 400;
    opacity: .6;
  }

  &__container {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
  }

  &__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: 2rem;

    &-title {
      margin-top: 1rem;
      padding: 0.4rem;
      border-radius: 0.6rem;
    }
  }

  &__item {
    cursor: pointer;
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    border-radius: 0.5rem;
    text-transform: capitalize;
  }
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .projects__container {
      grid-template-columns: max-content;
      row-gap: 2rem;
  }
}

/* For medium devices */
@media screen and (max-width: 576px) {
  .projects__container {
      grid-template-columns: 1fr;
  }

  .projects__content {
      padding: 1.5rem;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .projects__box {
      column-gap: 1.25rem;
  }

  .projects__name {
      font-size: var(--small-font-size);
  }
}
