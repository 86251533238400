.qualifications {
  text-align: center;
  padding: 3.5rem 0;

  &__title {
    font-size: 2.6rem;
    font-weight: 600;
  }

  &__subtitle {
    font-size: 1rem;
    font-weight: 400;
    opacity: .6;
  }

  &__tabs {
    display: flex;
    justify-content: center;
    margin: 3rem 0 1rem 0;
    gap: 3rem;
  }

  &__button {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 2rem 2rem;
    cursor: pointer;
  }

  &__icon {
    font-size: 1.8rem;
    margin-right: .25rem;
  }

  &__active {
    border-bottom: .15rem solid #636363;
  }

  &__sections {
    display: flex;
    gap: 1rem;
    justify-content: center;
    height: 22rem;
  }

  &__content {
      display: none;

      &-active {
        display: block;
      }
  }

  &__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
    text-align: left;

    &-title {
      font-weight: 500;
      font-size: 1rem;
    }

    &-subtitle {
      display: inline-block;
      font-size: .85rem;
      margin-bottom: .8rem;
      opacity: .75;
    }

    &-calender {
      display: flex;
      align-items: center;
      gap: .4rem;
      font-size: .75rem;
    }
  }

  &__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: rgb(109, 109, 109);
    border-radius: 50%;
  }

  &__line {
      display: block;
      width: 1px;
      height: 100%;
      background-color: rgb(109, 109, 109);
      transform: translate(6px, -7px);
  }
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .qualification__container {
      margin-left: auto;
      margin-right: auto;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .qualifications__container {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
  }
}

@media screen and (max-width: 576px) {
  .qualifications__sections {
      grid-template-columns: initial;
  }

  .qualifications__button {
      margin: 0 var(--mb-0-75);
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .qualifications__data {
      gap: 0.5rem;
  }
}
