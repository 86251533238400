.skills {
  text-align: center;
  padding: 3.5rem 0;
  
  &__title {
    font-size: 2.6rem;
    font-weight: 600;
  }
  
  &__subtitle {
    font-size: 1rem;
    font-weight: 400;
    opacity: .6;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 350px);
    column-gap: 3rem;
    justify-content: center;
  }
  
}

@media screen and (min-width: 320px) and (max-width: 480px){
  .skills {
    &__container {
      display: flex;
      flex-direction: column;
      padding: 1rem;
    }
  }  
}