.item {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-align: left;

  &__img {
    width: 16rem;
    height: 9rem;
    border-radius: 1rem;
    margin: auto;
  }

  &__title {
    font-size: 1.1;
    font-weight:  600;
    margin: 1rem 0;
  }

  &__link {
    display: flex;
    margin-top: 1rem;
  }
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .item {
    &__img {
      width: 20rem;
      height: 11rem;
    }
  } 
}

/* For medium devices */
@media screen and (max-width: 576px) {
  .item {
    &__img {
      width: 20rem;
      height: 11rem;
    }
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .projects__box {
      column-gap: 1.25rem;
  }

  .projects__name {
      font-size: var(--small-font-size);
  }
}
