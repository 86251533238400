.about {
  text-align: center;
  padding: 1.5rem 0;

  &__title {
    font-size: 2.6rem;
    font-weight: 600;
  }

  &__subtitle {
    font-size: 1rem;
    font-weight: 400;
    opacity: .6;
  }

  &__container {
    display: flex;
    justify-content: center;
  }

  &__data {
    width: 37rem;
    margin-top: 3.5rem;

    &-title {
      margin-top: .25rem;
      font-weight: 500;
    }

    &-subtitle {
      font-size: .85rem;
      opacity: .6;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(3, 8rem);
    gap: 0.5rem;
    margin-bottom: 2rem;
    justify-content: center;
    padding: 1rem;

    &-box {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.75rem;
      text-align: center;
      padding: 1rem 1.25rem;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }

  &__description {
    text-align: left;
    font-size: 1.1rem;
    font-weight: 300;
    padding: 0 1rem;
    margin-left: 5rem;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px){
  .about {
    text-align: center;
    
    &__description {
      margin-left: 0rem;
    }
  }
}